import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import shortid from 'shortid';

/**
 * CarouselGallery
 * carousel gallery component
 * @param {*} param0 
 * @returns 
 */
function CarouselGallery({ galleries }) {

  const settings = {
    dots: false,
    fade: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className="carousel-gallery">
      <Slider {...settings}>
        {
          galleries.map( photo => 
            <div className="wrap-img" key={shortid.generate()}>
              <img src={photo.url} alt={photo.caption} style={{ maxWidth: `100%` }}/>
              <div className="caption-wrapper">
                <p>{photo.caption}</p>
              </div>
            </div>
          )
        }
      </Slider>
    </div>
  )
}

export default CarouselGallery
