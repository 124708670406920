import React from 'react'
import PageWrapper from '../../components/page-wrapper'
import styled from '@emotion/styled'

const GreetingWrapper = styled.h1`
  color: white;
  margin: 0;
`

const KaltimWrapper = styled.div`
  background-color: rgba(0,0,0, 0.2);
  padding: 8px 16px;
  border-radius: 16px;
  display: inline-block;
  h2 {
    margin: 0;
    color: white;
  }
`

/**
 * PageHome
 * @returns 
 */
function PageHome({ bg }) {
  return (
    <div id="page-home" className="page-home">
      <PageWrapper urlImg={bg}>
        <div className="container align-items-center  h-100">
          <div className="row h-100">
            <div className="col-12 align-self-center text-center col-home-greetings">
              <GreetingWrapper className="font-bebas text-selamat-datang">
                Promosi & Informasi
              </GreetingWrapper>
              
              <KaltimWrapper>
                <h2 className="font-lobster text-kalimatan-timur">
                  Kalimantan Timur
                </h2>
              </KaltimWrapper>

            </div>
          </div>
        </div>
      </PageWrapper>
    </div>
  )
}

export default PageHome