
export const youtubeExtractId = url => {
  let newVal = ''
  let id = ''
  /* eslint-disable */
  if (url.match(/(\?|&)v=([^&#]+)/)) {
    newVal = url.match(/(\?|&)v=([^&#]+)/)
    id = newVal.pop()
  } else if (url.match(/(\.be\/)+([^\/]+)/)) {
    newVal = url.match(/(\.be\/)+([^\/]+)/)
    id = newVal.pop()
  } else if (url.match(/(\embed\/)+([^\/]+)/)) {
    newVal = url.match(/(\embed\/)+([^\/]+)/)
    id = newVal.pop().replace('?rel=0', '')
  }
  return id
}