import React, {memo} from 'react'
import styled from '@emotion/styled'

const PageContentWrapper = styled.div`
  background-color: rgba(0,0,0, 0.4);
`

/**
 * Content
 * @param {*} param0 
 * @returns 
 */
function Content({ children }) {
  return (
    <PageContentWrapper className="page-content-wrapper">
      {children}
    </PageContentWrapper>
  )
}
const PageContent = memo(Content)

export default PageContent