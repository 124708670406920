import React, { useState, useReducer, useEffect } from 'react'
import PageTemplateDefault from '../../components/page-template/default'
import PageWrapper from '../../components/page-wrapper'
import Modal from '../../components/modal'
import YoutubeEmbed from '../../components/video/youtube'
import { youtubeExtractId } from '../../utils/video'
import CarouselGallery from '../../components/carousel'
import { defaultStateDataAPI, fetchDataAPIReducer } from '../../utils/reducer'
import { requestService } from '../../utils/http'
import { API_GALLERY, API_PROFILE } from '../../constants/api'

/**
 * PageInformasiIkn
 * @returns 
 */
function PageInformasiIkn({ labelTitle }) {
  const [isOpenGallery, setIsOpenGallery] = useState(false)
  const [isOpenVideo, setIsOpenVideo] = useState(false)
  const slug = 'informasi-ikn'
  const [dataProfile, setDataProfile] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})
  const [dataGallery, setDataGallery] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})

  /**
   * get data profile
   */
  useEffect(() => {
    if (!dataProfile.loading && dataProfile.data === null) {
      setDataProfile({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: `${API_PROFILE}${slug}`
      })
        .then( response => {
          setDataProfile({ type: 'FETCH_DATA_SUCCESS', payload: response.data.data['profile'] })
        })
        .catch( e => {
          setDataProfile({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataProfile])

  useEffect(() => {
    if (dataGallery.data === null && !dataGallery.loading) {
      setDataGallery({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: `${API_GALLERY}${slug}`
      })
        .then( response => {
          setDataGallery({ type: 'FETCH_DATA_SUCCESS', payload: response.data.data['gallery'] })
        })
        .catch( e => {
          setDataGallery({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataGallery])

  return (
    <div id="page-informasi-ikn" className="page-informasi-ikn">
      {
        dataProfile.data !== null && (
          <PageWrapper urlImg={dataProfile.data.url_path !== null ? dataProfile.data.url_path : ''}>
            <PageTemplateDefault
              pageTitle={labelTitle}
              pageContentText={dataProfile.data !== null ? dataProfile.data.description : ''}
              linkSeeMore={dataProfile.data.link_informasi_lanjutan !== null ? dataProfile.data.link_informasi_lanjutan : null}
            >
              {
                (dataGallery.data !== null) && (
                  dataGallery.data.length > 0 && (
                    <>
                      <button
                        type="button"
                        className="btn mx-2 btn-danger"
                        onClick={() => setIsOpenGallery(!isOpenGallery)}  
                      >
                        Gallery
                      </button>
                      <Modal
                        onClose={() => { setIsOpenGallery(!isOpenGallery) }}
                        open={isOpenGallery}
                      >
                        <CarouselGallery galleries={dataGallery.data}/>
                      </Modal>
                    </>
                  )
                )
              }
              {
                dataProfile.data !== null && (
                  dataProfile.data.link_video !== null && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary mx-2"
                        onClick={() => setIsOpenVideo(!isOpenVideo)}  
                      >
                        Video
                      </button>

                      <Modal
                        onClose={() => { setIsOpenVideo(!isOpenVideo) }}
                        open={isOpenVideo}
                      >
                        <YoutubeEmbed id={youtubeExtractId(dataProfile.data.link_video)} />
                      </Modal>
                    </>
                  )
                )
              }
            </PageTemplateDefault>
          </PageWrapper>
        )
      }
    </div>
  )
}

export default PageInformasiIkn