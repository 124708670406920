import React, { memo } from 'react' 
import { SocialIcon } from 'react-social-icons'

/**
 * SocialItem
 * social item for display social icon 
 * @param {*} param0 
 * @returns 
 */
function SocialItem({ url, network }) {
  return (
    <SocialIcon
      className="link-social-media"
      target="_blank" 
      url={url}
      network={network}
      style={{ height: 24, width: 24 }}
    />
  )
} 

const SocialIconItem = memo(SocialItem)

export default SocialIconItem