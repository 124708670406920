export const API_SOCIAL_MEDIA = 'api/cf/social-media'
export const API_ADS = 'api/cf/advertising'

export const API_GALLERY = 'api/cf/gallery/'
export const API_PROFILE = 'api/cf/profile/'
export const API_LIST_AGENDA = 'api/cf/list-agenda'
export const API_LIST_INVESTOR = 'api/cf/list-investor'

export const API_GALLERY_AGENDA_ANJUNGAN = 'api/cf/gallery/agenda-anjungan'
export const API_GALLERY_KAWASAN_PERBATASAN = 'api/cf/gallery/kawasan-perbatasan'
export const API_GALLERY_WISATA_KALTIM = 'api/cf/gallery/wisata-kaltim'
export const API_GALLERY_INFORMASI_IKN = 'api/cf/gallery/informasi-ikn'
export const API_GALLERY_SENI_BUDAYA = 'api/cf/gallery/seni-budaya'
export const API_GALLERY_POTENSI_INVESTASI = 'api/cf/gallery/potensi-investasi'
export const API_GALLERY_INDUSTRI_PERDAGANGAN = 'api/cf/gallery/industri-perdagangan'
export const API_GALLERY_PEREKONOMIAN = 'api/cf/gallery/perekonomian'

export const API_PROFILE_AGENDA_ANJUNGAN = 'api/cf/profile/agenda-anjungan'
export const API_PROFILE_KAWASAN_PERBATASAN = 'api/cf/profile/kawasan-perbatasan'
export const API_PROFILE_WISATA_KALTIM = 'api/cf/profile/wisata-kaltim'
export const API_PROFILE_INFORMASI_IKN = 'api/cf/profile/informasi-ikn'
export const API_PROFILE_SENI_BUDAYA = 'api/cf/profile/seni-budaya'
export const API_PROFILE_POTENSI_INVESTASI = 'api/cf/profile/potensi-investasi'
export const API_PROFILE_INDUSTRI_PERDAGANGAN = 'api/cf/profile/industri-perdagangan'
export const API_PROFILE_PEREKONOMIAN = 'api/cf/profile/perekonomian'