import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import shortid from 'shortid'

function CarouselAds({ listAds }) {

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className="carousel-ads">
      <Slider {...settings}>
        {
          listAds.map( ads => 
            <div className="wrap-img-ads" key={shortid.generate()}>
              <img src={ads.url} alt={ads.name} />
            </div>
          )
        }
      </Slider>
    </div>
  )
}

export default CarouselAds