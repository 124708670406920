import axios from 'axios'

axios.defaults.withCredentials = true;

const getBaseURL = (baseURL = '') => baseURL !== '' ? baseURL : ''

const setHeadersConfig = () => {
  return {
    Authorization: `Bearer ${window.access_value || process.env.REACT_APP_ACCESS_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

const postRequest = (baseURL, { url, dataParams }, headersConfig) => {
  const data = dataParams
  return axios.post(
    `${getBaseURL(baseURL)}/${url}`,
    data,
    {
      headers: headersConfig
    }
  )
}

const getRequest = (baseURL, { url, dataParams }, headersConfig) => {
  return axios.get(
    `${getBaseURL(baseURL)}/${url}`,
    {
      headers: headersConfig,
      params: dataParams
    }
  )
}

const putRequest = (baseURL, { url, dataParams }, headersConfig) => {
  const params = new URLSearchParams({...dataParams})
  return axios.put(
    `${getBaseURL(baseURL)}/${url}`,
    params,
    {
      headers: headersConfig
    }
  )
}

export const requestService = params => {
  let request = null
  const headersConfig = setHeadersConfig()
  const baseURL = process.env.REACT_APP_BASE_URL_API || ''
  switch(params.method) {
    case 'post': 
      request = postRequest(baseURL, params, headersConfig)
      break;
    case 'get':
      request = getRequest(baseURL, params, headersConfig)
      break;
    case 'put':
      request = putRequest(baseURL, params, headersConfig)
      break;
    default:
      request = getRequest(baseURL, params, headersConfig)
  }
  return request
}