import React, { memo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const TitleHeading = styled.h1`
  color: white;
  text-align: ${props => props.align};
  text-shadow: 2px 2px rgba(0,0,0, 0.8);
`
/**
 * 
 * @returns 
 */
function Title({ children, align }) {
  return (
    <TitleHeading
      className="font-bebas page-title"
      align={align}
    >
      {children}
    </TitleHeading>
  )
}

Title.propTypes = {
  align: PropTypes.string
}

Title.defaultProps = {
  align: 'center'
}

const PageTitle = memo(Title)

export default PageTitle