import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import shortid from 'shortid'
import { motion } from 'framer-motion'
import { menuMotion } from '../../utils/motion'

const MotionLink = motion(Link)

/**
 * MenuNavigation
 * menu navigation component
 * @returns 
 */
function Menu({ menu }) {
  return (
    <div id="menu-navigation" className="menu-navigation">
      <motion.div className="menu-wrapper"
        variants={menuMotion.container}
        initial="hidden"
        animate="visible"
      >
      {
        menu.map( route => 
          <MotionLink 
            className="nav-item" 
            to={route.path}
            variants={menuMotion.item}
            key={shortid.generate()}
          >
            <motion.div 
              className="icon"
              whileTap={{ scale: 0.9 }}
              style={{ backgroundImage: `url(${route.iconMenu})`}}
            />
            <span className="nav-item-text">
              {route.label.toUpperCase()}
            </span>
          </MotionLink>
        )
      }
      </motion.div>
    </div>
  )
}

const MenuNavigation = memo(Menu)

export default MenuNavigation