import React, { useState, useReducer, useEffect, memo } from 'react'
import PageWrapper from '../../components/page-wrapper'
import PageTemplateDefault from '../../components/page-template/default'
import Modal from '../../components/modal'
import YoutubeEmbed from '../../components/video/youtube'
import CarouselGallery from '../../components/carousel'
import { youtubeExtractId } from '../../utils/video'
import { defaultStateDataAPI, fetchDataAPIReducer } from '../../utils/reducer'
import { requestService } from '../../utils/http'
import { API_GALLERY, API_LIST_AGENDA, API_PROFILE } from '../../constants/api'
import spacetime from 'spacetime'

const timezone = 'Asia/Jakarta'

/**
 * PageAgendaAnjungan
 * @returns 
 */
function AgendaAnjungan({ labelTitle }) {
  const slug = 'agenda-anjungan'
  const [isOpenGallery, setIsOpenGallery] = useState(false)
  const [isOpenVideo, setIsOpenVideo] = useState(false)
  const [isOpenAgenda, setIsOpenAgenda] = useState(false)
  const [dataProfile, setDataProfile] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})
  const [dataGallery, setDataGallery] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})
  const [dataListAgenda, setDataListAgenda] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})

  /**
   * get data profile
   */
  useEffect(() => {
    if (!dataProfile.loading && dataProfile.data === null) {
      setDataProfile({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: `${API_PROFILE}${slug}`
      })
        .then( response => {
          setDataProfile({ type: 'FETCH_DATA_SUCCESS', payload: response.data.data['profile'] })
        })
        .catch( e => {
          setDataProfile({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataProfile])

  useEffect(() => {
    if (dataGallery.data === null && !dataGallery.loading) {
      setDataGallery({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: `${API_GALLERY}${slug}`
      })
        .then( response => {
          setDataGallery({ type: 'FETCH_DATA_SUCCESS', payload: response.data.data['gallery'] })
        })
        .catch( e => {
          setDataGallery({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataGallery])

  /**
   * get list agenda
   */
  useEffect(() => {
    if (dataListAgenda.data === null && !dataListAgenda.loading) {
      setDataListAgenda({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: API_LIST_AGENDA
      })
        .then( response => {
          const data = response.data.data['agenda'].map( agenda => {
            return {...agenda, dateFormat: new Date(agenda.date)}
          })
          const sortedData = data.sort((a, b) => a.dateFormat - b.dateFormat)
          setDataListAgenda({ type: 'FETCH_DATA_SUCCESS', payload: sortedData })
        })
        .catch( e => {
          setDataListAgenda({ type: 'FETCH_DATA_ERROR', payload: e.reponse.data })
        })
    }
  }, [dataListAgenda])

  return (
    <div id="page-agenda-anjungan" className="page-agenda-anjungan">
      {
        dataProfile.data !== null && (
          <PageWrapper urlImg={dataProfile.data.url_path !== null ? dataProfile.data.url_path : ''}>
            <PageTemplateDefault
              pageTitle={labelTitle}
              pageContentText={dataProfile.data !== null ? dataProfile.data.description : ''}
              linkSeeMore={dataProfile.data.link_informasi_lanjutan !== null ? dataProfile.data.link_informasi_lanjutan : null}
            >
              {
                dataListAgenda.data !== null && (
                  dataListAgenda.data.length > 0 && (
                    <>
                      <button
                        type="button"
                        className="btn mx-2 btn-info"
                        onClick={() => { setIsOpenAgenda(!isOpenAgenda) }}
                      >
                        Agenda
                      </button>
                      <Modal
                        onClose={() => { setIsOpenAgenda(!isOpenAgenda) }}
                        open={isOpenAgenda}
                      >
                        <div className="p-3 p-md-5">
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                              {
                                dataListAgenda.data.map( (agenda, index) => {
                                  const d = spacetime(agenda.dateFormat, timezone)
                                  return (
                                      <div key={index} className="list-agenda-item">
                                        <div className="list-agenda-item--name">
                                          {agenda.name}
                                        </div>
                                        <span className="list-agenda-item--date">
                                          {d.date()} {d.monthName()} {d.year()}
                                          </span>
                                        <div className="list-agenda-item--desc">
                                          {agenda.description}
                                        </div>
                                      </div>
                                  )
                                })
                              }
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </>
                  )
                )
              }
              {
                (dataGallery.data !== null) && (
                  dataGallery.data.length > 0 && (
                    <>
                      <button
                        type="button"
                        className="btn mx-2 btn-danger"
                        onClick={() => setIsOpenGallery(!isOpenGallery)}  
                      >
                        Gallery
                      </button>
                      <Modal
                        onClose={() => { setIsOpenGallery(!isOpenGallery) }}
                        open={isOpenGallery}
                      >
                        <CarouselGallery galleries={dataGallery.data}/>
                      </Modal>
                    </>
                  )
                )
              }
              {
                dataProfile.data !== null && (
                  dataProfile.data.link_video !== null && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary mx-2"
                        onClick={() => setIsOpenVideo(!isOpenVideo)}  
                      >
                        Video
                      </button>

                      <Modal
                        onClose={() => { setIsOpenVideo(!isOpenVideo) }}
                        open={isOpenVideo}
                      >
                        <YoutubeEmbed id={youtubeExtractId(dataProfile.data.link_video)} />
                      </Modal>
                    </>
                  )
                )
              }
            </PageTemplateDefault>
          </PageWrapper>
        )
      }
    </div>
  )
}

const PageAgendaAnjungan = memo(AgendaAnjungan)

export default PageAgendaAnjungan