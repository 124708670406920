import React, { memo } from 'react'
import PageWrapper from '../page-wrapper'
import PageContent from '../typography/page-content'
import PageTitle from '../typography/page-title'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const PageCol = styled.div`
  // padding-top: 80px;
`

const ContainerChildren = styled.div`
  margin-top: 30px;
`

const WrapperSeeMore = styled.div`
  text-align: center;
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
  a {
    color: white;
  }
`

/**
 * PageTemplateDefault
 * page template default component 
 * @param {*} param0 
 * @returns 
 */
function PageTemplate({ children, pageTitle, pageContentText, linkSeeMore, galleries, video, seeMore }) {
  return (
    <PageWrapper>
      <div className="container align-self-center h-100">
        <div className="row h-100">
          <PageCol className="col-12 align-self-center">
            <PageTitle>
              {pageTitle}
            </PageTitle>

            {
              pageContentText !== null && (
                <PageContent>
                  <div dangerouslySetInnerHTML={{ __html: pageContentText }} />
                  {
                    linkSeeMore !== null && (
                      <WrapperSeeMore>
                        <a href={linkSeeMore} target="_blank" rel="noreferrer">
                          Informasi lebih lanjut
                        </a>
                      </WrapperSeeMore>
                    )
                  }
                </PageContent>
              )
            }
            

            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 mx-auto" style={{ textAlign: `center` }}>
                  <ContainerChildren>
                    {children}
                  </ContainerChildren>
                </div>
              </div>
            </div>
          </PageCol>
        </div>
      </div>
    </PageWrapper>
  )
}

PageTemplate.propTypes = {
  pageTitle: PropTypes.string,
  pageContentText: PropTypes.string,
  galleries: PropTypes.array,
  video: PropTypes.string,
  seeMore: PropTypes.string
}

PageTemplate.defaultProps = {
  pageTitle: 'Defautl Page Title',
  pageContentText: null,
  galleries: [{}],
  video: null,
  seeMore: null
}

const PageTemplateDefault = memo(PageTemplate)

export default PageTemplateDefault