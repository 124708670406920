import React from 'react'
import PropTypes from 'prop-types'

function YoutubeEmbed({ id }) {
  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}

YoutubeEmbed.propTypes = {
  id: PropTypes.string.isRequired
}

export default YoutubeEmbed