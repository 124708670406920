
export const defaultStateDataAPI = {
  loading: false,
  data: null,
  error: null,
  isLoaded: false
}

export const fetchDataAPIReducer = (state, action) => {
  switch(action.type) {
    case 'FETCH_DATA_START':
      return {
        ...state,
        loading: true,
      }
    case 'FETCH_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        isLoaded: true
      }
    case 'FETCH_DATA_ERROR':
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
        isLoaded: true
      }
    default: 
      throw new Error()
  }
}