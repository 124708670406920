import React, { useState, useReducer, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { mainRouter } from './routes'
import MenuNavigation from './components/menu'
import SectionAds from './components/ads'
import { AnimatePresence } from 'framer-motion'
import MenuNavigationMobile from './components/menu/mobile'
import { ThreeBarsIcon } from '@primer/octicons-react'
import SocialIconList from './components/social-media/list'
import { defaultStateDataAPI, fetchDataAPIReducer } from './utils/reducer'
import { API_SOCIAL_MEDIA } from './constants/api'
import { requestService } from './utils/http'

/**
 * App
 * App components
 * @returns 
 */
function App() {
  const location = useLocation()
  const [menuMobile, setMenuMobile] = useState(false)
  const [bgHome, setBgHome] = useState(null)
  const [dataSocialMedia, setDataSocialMedia] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI, data: []})
  
  /**
   * get social media
   */
   useEffect(() => {
    if (!dataSocialMedia.loading && dataSocialMedia.data.length === 0) {
      setDataSocialMedia({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: API_SOCIAL_MEDIA
      })
        .then( response => {
          const { facebook_link, instagram_link, youtube_link } = response?.data.data['social-media']
          const { url_path } = response?.data.data['profile']
          const data = [
            { url: facebook_link, type: 'facebook' },
            { url: instagram_link, type: 'instagram' },
            { url: youtube_link, type: 'youtube' }
          ]
          setDataSocialMedia({ type: 'FETCH_DATA_SUCCESS', payload: data })
          setBgHome(url_path)
        })
        .catch( e => {
          setDataSocialMedia({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataSocialMedia])
  
  return (
    <div className="App">
      <div className="page-content">
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {
              mainRouter.map( (route, index) => 
                <Route
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                    route.component({ ...props, bg: bgHome, labelTitle: route.label })
                  )}
                  key={index}
                />  
                )
              }
          </Switch>
        </AnimatePresence>
        <MenuNavigation menu={mainRouter}/>
        <SocialIconList dataSocialMedia={dataSocialMedia}/>
      </div>
      <div className="page-ads">
        <SectionAds />
      </div>
      <div className="button-bars-mobile" onClick={() => setMenuMobile(!menuMobile)}>
        <ThreeBarsIcon size={32} />
      </div>
      <MenuNavigationMobile
        setStatus={setMenuMobile}
        status={menuMobile}
        menu={mainRouter}
      />
    </div>
  );
}

export default App;
