import React, { memo, useEffect, useReducer } from 'react'
import { API_ADS } from '../../constants/api'
import { requestService } from '../../utils/http'
import { defaultStateDataAPI, fetchDataAPIReducer } from '../../utils/reducer'
import CarouselAds from '../carousel-ads'

// const photo = 'https://via.placeholder.com/468x160?text=Ads+Here'

/**
 * Ads
 * section ads component
 * @returns 
 */
function Ads() {
  const [dataAds, setDataAds] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})
  
  useEffect(() => {
    if (!dataAds.loading && dataAds.data === null) {
      setDataAds({ type: 'FETCH_DATA_START'})
      requestService({
        method: 'get',
        url: API_ADS
      })
        .then( response => {
          const { advertizing } = response.data.data
          setDataAds({ type: 'FETCH_DATA_SUCCESS', payload: advertizing })
        })
        .catch( e => {
          setDataAds({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataAds])

  return (
    <div id="section-ads" className="section-ads">
      <CarouselAds listAds={dataAds.data || []} />
    </div>
  )
}

const SectionAds = memo(Ads)

export default SectionAds