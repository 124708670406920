import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import shortid from 'shortid'
import { motion } from 'framer-motion'

const MotionLink = motion(Link)

/**
 * MenuMobile
 * menu for mobile screen
 * @returns 
 */
function MenuMobile({ setStatus, status, menu }) {
  return (
    <div
      id="menu-navigation-mobile"
      className={`menu-navigation-mobile ${status ? 'active' : ''}`}
      onClick={() => setStatus(!status)}
    >
      
      <div className="menu-mobile-wrapper">
      {
        menu.map( route => 
          <MotionLink
            className="nav-item-mobile"
            to={route.path}
            key={shortid.generate()}
          >
            <span className="nav-item-text-mobile">
              {route.label.toUpperCase()}
            </span>
          </MotionLink>
        )
      }
      </div>
    </div>
  )
}

const MenuNavigationMobile = memo(MenuMobile)

export default MenuNavigationMobile