import React, { useState, useEffect, useReducer } from 'react'
import PageTemplateDefault from '../../components/page-template/default'
import PageWrapper from '../../components/page-wrapper'
import Modal from '../../components/modal'
import CarouselGallery from '../../components/carousel'
import YoutubeEmbed from '../../components/video/youtube'
import { youtubeExtractId } from '../../utils/video'
import { defaultStateDataAPI, fetchDataAPIReducer } from '../../utils/reducer'
import { requestService } from '../../utils/http'
import { API_GALLERY, API_LIST_INVESTOR, API_PROFILE } from '../../constants/api'

/**
 * PagePotensiInvestasi
 * page pontensi investasi component
 * @returns 
 */
function PagePotensiInvestasi({ labelTitle }) {
  const [isOpenGallery, setIsOpenGallery] = useState(false)
  const [isOpenVideo, setIsOpenVideo] = useState(false)
  const [isOpenInvestor, setIsOpenInvestor] = useState(false)
  const slug = 'potensi-investasi'
  const [dataProfile, setDataProfile] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})
  const [dataGallery, setDataGallery] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})
  const [dataListInvestor, setDataListInvestor] = useReducer(fetchDataAPIReducer, {...defaultStateDataAPI})

  /**
   * get data profile
   */
  useEffect(() => {
    if (!dataProfile.loading && dataProfile.data === null) {
      setDataProfile({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: `${API_PROFILE}${slug}`
      })
        .then( response => {
          setDataProfile({ type: 'FETCH_DATA_SUCCESS', payload: response.data.data['profile'] })
        })
        .catch( e => {
          setDataProfile({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataProfile])

  /**
   * get list gallery
   */
  useEffect(() => {
    if (dataGallery.data === null && !dataGallery.loading) {
      setDataGallery({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: `${API_GALLERY}${slug}`
      })
        .then( response => {
          setDataGallery({ type: 'FETCH_DATA_SUCCESS', payload: response.data.data['gallery'] })
        })
        .catch( e => {
          setDataGallery({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataGallery])

  /**
   * get list investor
   */
  useEffect(() => {
    if (dataListInvestor.data === null && !dataListInvestor.loading) {
      setDataListInvestor({ type: 'FETCH_DATA_START' })
      requestService({
        method: 'get',
        url: `${API_LIST_INVESTOR}`
      })
        .then( response => {
          setDataListInvestor({ type: 'FETCH_DATA_SUCCESS', payload: response.data.data['listInvestor']})
        })
        .catch( e => {
          setDataListInvestor({ type: 'FETCH_DATA_ERROR', payload: e.response.data })
        })
    }
  }, [dataListInvestor])

  return (
    <div id="page-potensi-investasi" className="page-potensi-investasi">
      {
        dataProfile.data !== null && (
          <PageWrapper urlImg={dataProfile.data.url_path !== null ? dataProfile.data.url_path : ''}>
            <PageTemplateDefault
              pageTitle={labelTitle}
              pageContentText={dataProfile.data !== null ? dataProfile.data.description : ''}
              linkSeeMore={dataProfile.data.link_informasi_lanjutan !== null ? dataProfile.data.link_informasi_lanjutan : null}
            >
              {
                dataListInvestor.data !== null && (
                  dataListInvestor.data.length > 0 && (
                    <>
                      <button
                        type="button"
                        className="btn mx-2 btn-secondary"
                        onClick={() => setIsOpenInvestor(!isOpenInvestor)}
                      >
                        Investor
                      </button>
                      <Modal
                        onClose={() => { setIsOpenInvestor(!isOpenInvestor) }}
                        open={isOpenInvestor}
                      >

                        <div className="p-3 p-md-5 list-investor-wrapper">
                          <div className="container">
                            <div className="row">
                            {
                              dataListInvestor.data.map( (investor, index) => {
                                return (
                                  <div className="col-12 col-md-6" key={index} >
                                    <div className="list-investor-item">
                                      <div className="list-investor-item--name">
                                        {investor.name}
                                      </div>
                                      <div className="list-investor-item--desc">
                                        {investor.description}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </>
                  )
                )
              }
              {
                (dataGallery.data !== null) && (
                  dataGallery.data.length > 0 && (
                    <>
                      <button
                        type="button"
                        className="btn mx-2 btn-danger"
                        onClick={() => setIsOpenGallery(!isOpenGallery)}  
                      >
                        Gallery
                      </button>
                      <Modal
                        onClose={() => { setIsOpenGallery(!isOpenGallery) }}
                        open={isOpenGallery}
                      >
                        <CarouselGallery galleries={dataGallery.data}/>
                      </Modal>
                    </>
                  )
                )
              }
              {
                dataProfile.data.link_video !== null && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary mx-2"
                      onClick={() => setIsOpenVideo(!isOpenVideo)}  
                    >
                      Video
                    </button>
                    <Modal
                      onClose={() => { setIsOpenVideo(!isOpenVideo) }}
                      open={isOpenVideo}
                    >
                      <YoutubeEmbed id={youtubeExtractId(dataProfile.data.link_video)} />
                    </Modal>
                  </>
                )
              }
            </PageTemplateDefault>
          </PageWrapper>
        )
      }
    </div>
  )
}

export default PagePotensiInvestasi