import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { pageTransition } from '../../utils/motion'

const Page = styled(motion.div)`
  background-color: #ccc;
  background: url('${props => props.urlImg}');
  height: 80vh;
  position: relative;
  background-size: cover;
  background-position: center center;
`
/**
 * PageWrapper
 * @param {*} param0 
 * @returns 
 */
function PageWrapper({ children, urlImg, ...rest }) {
  return (
    <Page urlImg={urlImg} {...rest} {...pageTransition}>{children}</Page>
  )
}

export default PageWrapper