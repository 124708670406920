import React from 'react'
import { PAGE_AGENDA_ANJUNGAN, PAGE_HOME, PAGE_INDUSTRI_PERDAGANGAN, PAGE_INFORMASI_IKN, PAGE_KAWASAN_PERBATASAN, PAGE_PEREKONOMIAN, PAGE_POTENSI_INVESTASI, PAGE_SENI_BUDAYA, PAGE_WISATA_KALTIM } from "../constants/routes";
import PageHome from '../pages/home'
import PageAgendaAnjungan from '../pages/agenda-anjungan'
import PageKawasanPerbatasan from '../pages/kawasan-perbatasan'
import PageWisataKaltim from '../pages/wisata-kaltim'
import PageInformasiIkn from '../pages/informasi-ikn'
import PageSeniBudaya from '../pages/seni-budaya'
import PagePotensiInvestasi from '../pages/potensi-inventasi'
import PageIndustriPerdagangan from '../pages/industri-perdagangan'
import PagePerekonomian from '../pages/perekonomian'
import menuHome from '../menu-home.png'
import menuAgenda from '../menu-agenda-anjungan.png'
import menuKawasanPerbatasan from '../menu-kawasan-perbatasan.png'
import menuWisataKaltim from '../menu-pariwisata.png'
import menuInformasiIKN from '../menu-informasi-ikn.png'
import menuSeniBudaya from '../menu-seni-budaya.png'
import menuPotensiInvestasi from '../menu-investasi.png'
import menuIndustriPerdagangan from '../menu-industri-perdagangan.png'
import menuPerekonomian from '../menu-ekonomi.png'

export const mainRouter = [
  {
    path: PAGE_HOME,
    component: ({labelTitle, bg, ...props}) => <PageHome {...props} bg={bg} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Home',
    iconMenu: menuHome
  },
  {
    path: PAGE_AGENDA_ANJUNGAN,
    component: ({labelTitle, ...props}) => <PageAgendaAnjungan {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Agenda Anjungan',
    iconMenu: menuAgenda
  },
  {
    path: PAGE_KAWASAN_PERBATASAN,
    component: ({labelTitle, ...props}) => <PageKawasanPerbatasan {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Kawasan Perbatasan',
    iconMenu: menuKawasanPerbatasan
  },
  {
    path: PAGE_WISATA_KALTIM,
    component: ({labelTitle, ...props}) => <PageWisataKaltim {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Wisata Kaltim',
    iconMenu: menuWisataKaltim
  },
  {
    path: PAGE_INFORMASI_IKN,
    component: ({labelTitle, ...props}) => <PageInformasiIkn {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Informasi IKN',
    iconMenu: menuInformasiIKN
  },
  {
    path: PAGE_SENI_BUDAYA,
    component: ({labelTitle, ...props}) => <PageSeniBudaya {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Seni Budaya',
    iconMenu: menuSeniBudaya
  },
  {
    path: PAGE_POTENSI_INVESTASI,
    component: ({labelTitle, ...props}) => <PagePotensiInvestasi {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Potensi Investasi',
    iconMenu: menuPotensiInvestasi
  },
  {
    path: PAGE_INDUSTRI_PERDAGANGAN,
    component: ({labelTitle, props}) => <PageIndustriPerdagangan {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Industri Perdagangan',
    iconMenu: menuIndustriPerdagangan
  },
  {
    path: PAGE_PEREKONOMIAN,
    component: ({labelTitle, props}) => <PagePerekonomian {...props} labelTitle={labelTitle}/>,
    exact: true,
    label: 'Bidang Ekonomi',
    iconMenu: menuPerekonomian
  }
]