import React from 'react'
import shortid from 'shortid'
import SocialIconItem from './item'

/**
 * SocialIconList
 * @param {*} param0 
 * @returns 
 */
function SocialIconList({ dataSocialMedia }) {
  return (
    <div className="social-icon-list">
      {
        dataSocialMedia.data.length > 0 && (
          dataSocialMedia.data.map( item => 
            <SocialIconItem url={item.url} network={item.type} key={shortid.generate()} />  
          )
        )
      }
    </div>
  )
}

export default SocialIconList